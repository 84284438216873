import type { InitOptions } from 'i18next';

export const fallbackLng = 'en-US';
export const locales = ['en-US', 'fr-FR', 'en', 'fr'] as const;
export type LocaleTypes = (typeof locales)[number];

export function getOptions(lang = fallbackLng): InitOptions {
  return {
    // debug: true, // Set to true to see console logs
    supportedLngs: locales,
    fallbackLng,
    lng: lang,
    react: { useSuspense: true },
    nonExplicitSupportedLngs: true,
    keySeparator: '/',
    cache: [],
  };
}
