'use client';

import React, { useState } from 'react';

import Contexts from '../contexts';

export function storageAvailable(type: any) {
  try {
    const storage: any = window[type],
      x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
}

const LocalStorageProvider = ({ children }: { children: React.ReactNode }) => {
  const [isStorageEnabled, setIsStorageEnabled] = useState<boolean>(true);
  const [memoryStorage, setMemoryStorage] = useState<{ [key: string]: string }>({});

  const setItem = (item: string, value: string): void => {
    if (isStorageEnabled) return window.localStorage.setItem(item, value);
    else {
      const data = setMemoryStorage({ ...memoryStorage, [item]: value });
      return data;
    }
  };

  const getItem = (item: string): string | null => {
    if (isStorageEnabled) return window.localStorage.getItem(item);
    else return memoryStorage[item] ? memoryStorage[item] : null;
  };

  const removeItem = (item: string): void => {
    if (isStorageEnabled) window.localStorage.removeItem(item);
    else {
      const copyMemoryStorage = { ...memoryStorage };

      delete copyMemoryStorage[item];

      setMemoryStorage(copyMemoryStorage);
    }
  };

  const clear = (): void => {
    if (isStorageEnabled) window.localStorage.clear();
    else setMemoryStorage({});
  };

  const length = (): number => {
    return Object.keys(memoryStorage).length;
  };

  const localStorage = { setItem, getItem, removeItem, clear, length };

  return <Contexts.StorageContext.Provider value={localStorage}>{children}</Contexts.StorageContext.Provider>;
};

export default LocalStorageProvider;
