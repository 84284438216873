import { setCookie } from 'cookies-next';
import { ReadonlyURLSearchParams } from 'next/navigation';

const utmManager = (searchParams: ReadonlyURLSearchParams): void => {
  const utm_datas = {
    gad_source: searchParams.has('gad_source') ? searchParams.get('gad_source') : '',
    gclid: searchParams.has('gclid') ? searchParams.get('gclid') : '',
    utm_source: searchParams.has('utm_source') ? searchParams.get('utm_source') : '',
    bp_id: searchParams.has('bp_id') ? searchParams.get('bp_id') : '',
    utm_medium: searchParams.has('utm_medium') ? searchParams.get('utm_medium') : '',
    utm_campaign: searchParams.has('utm_campaign') ? searchParams.get('utm_campaign') : '',
    utm_content: searchParams.has('utm_content') ? searchParams.get('utm_content') : '',
    utm_term: searchParams.has('utm_term') ? searchParams.get('utm_term') : '',
    fbclid: searchParams.has('fbclid') ? searchParams.get('fbclid') : '',
  };

  if (window.navigator.cookieEnabled && typeof sessionStorage !== 'undefined' && typeof localStorage !== 'undefined') {
    if (
      localStorage.getItem('consentMode') &&
      JSON.parse(localStorage.getItem('consentMode') as string).analytics_storage &&
      JSON.parse(localStorage.getItem('consentMode') as string)?.analytics_storage === 'granted'
    ) {
      // TODO => Do nothing
    }

    // 1 month cookie
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + 1);

    for (const key in utm_datas) {
      if (utm_datas[key] === '') {
        delete utm_datas[key];
      }
    }

    if (Object.keys(utm_datas).length > 0)
      setCookie('utm_datas', JSON.stringify(utm_datas), {
        expires: currentDate,
        domain:
          location.hostname.split('.').length > 2
            ? '.' +
              location.hostname
                .split('.')
                .slice(location.hostname.split('.').length - 2, location.hostname.split('.').length)
                .join('.')
            : '.' + location.hostname,
        secure: true,
        sameSite: 'none',
      });
  }
};

const Utils = {
  utmManager,
};

export default Utils;
