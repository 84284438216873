const HumanIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 6C7.10457 6 8 5.10457 8 4C8 2.89543 7.10457 2 6 2C4.89543 2 4 2.89543 4 4C4 5.10457 4.89543 6 6 6ZM18 6C19.1046 6 20 5.10457 20 4C20 2.89543 19.1046 2 18 2C16.8954 2 16 2.89543 16 4C16 5.10457 16.8954 6 18 6ZM21.7071 7.29289C21.5196 7.10536 21.2652 7 21 7H15C14.7348 7 14.4804 7.10536 14.2929 7.29289C14.1054 7.48043 14 7.73478 14 8V10H10V8C10 7.73478 9.89464 7.48043 9.70711 7.29289C9.51957 7.10536 9.26522 7 9 7H3C2.73478 7 2.48043 7.10536 2.29289 7.29289C2.10536 7.48043 2 7.73478 2 8V15H4V22H8V15H10V12H14V15H16V22H20V15H22V8C22 7.73478 21.8946 7.48043 21.7071 7.29289ZM10 12V10H6V12H10ZM14 12V10H16V8L20 11L16 14V12H14Z"
        fill="#6B7280"
      />
    </svg>
  );
};

export default HumanIcon;
