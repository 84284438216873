export const BusinessPartnerPlanIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.90629 7.31478C8.56519 7.83514 8.20479 8.30573 7.92984 8.82986C7.71209 9.2454 7.53431 9.71297 7.4523 10.1805C7.23179 11.4468 8.12624 12.4008 9.29149 12.1919C9.88067 12.0855 10.4016 11.8291 10.8413 11.3706C11.2547 10.94 11.7233 10.5953 12.2739 10.397C12.4393 10.3374 12.5544 10.3578 12.688 10.492C14.5989 12.4143 16.516 14.3291 18.4296 16.2484C18.7638 16.584 18.7975 17.0259 18.5267 17.3442C18.2442 17.6753 17.8411 17.6647 17.5027 17.3072C16.5318 16.2816 15.5629 15.2537 14.5934 14.2273C14.5686 14.2009 14.5445 14.1745 14.5196 14.1489C14.2495 13.8661 13.998 13.8306 13.8023 14.0486C13.599 14.2741 13.6328 14.5433 13.9022 14.8306C14.0497 14.9882 14.1992 15.1436 14.3474 15.3012C15.0571 16.0538 15.7683 16.805 16.4753 17.5614C16.7309 17.8344 16.7923 18.179 16.6593 18.4867C16.5256 18.7959 16.2245 19.0078 15.9254 18.9279C15.738 18.8781 15.543 18.7514 15.4065 18.6006C14.5892 17.6994 13.7885 16.7808 12.9816 15.8676C12.9396 15.8201 12.8982 15.7718 12.8548 15.7273C12.6432 15.5093 12.3952 15.4935 12.2126 15.6836C12.0224 15.8827 12.0265 16.1692 12.236 16.409C12.657 16.8925 13.0836 17.3698 13.5074 17.851C13.8057 18.1896 14.1103 18.5222 14.3963 18.8728C14.6051 19.1293 14.6113 19.4852 14.4452 19.7627C14.286 20.029 13.9897 20.167 13.7017 20.0614C13.5542 20.0078 13.413 19.8947 13.3006 19.7733C12.5537 18.9686 11.815 18.1534 11.0728 17.3427C11.0184 17.2831 10.966 17.219 10.9054 17.167C10.7117 17.0011 10.4588 17.0214 10.3003 17.2092C10.148 17.3902 10.1467 17.6474 10.3003 17.8495C10.3499 17.9143 10.4071 17.9716 10.4616 18.032C11.057 18.6843 11.6558 19.3321 12.2463 19.9897C12.5702 20.3502 12.473 20.9 12.0568 20.992C11.8976 21.0274 11.6764 20.9566 11.5393 20.8487C9.89652 19.5606 8.30885 18.1934 6.82867 16.6873C5.8543 15.6956 4.93367 14.6398 3.98478 13.6172C3.85937 13.4814 3.84007 13.3563 3.89245 13.1685C4.41891 11.2929 4.93504 9.41357 5.45462 7.53499C5.48632 7.4196 5.50286 7.31101 5.66342 7.31252C6.73771 7.32081 7.81132 7.31704 8.9056 7.31704L8.90629 7.31478Z"
        fill="#6B7280"
      />
      <path
        d="M12.5009 6.0957C12.7759 6.17338 13.0591 6.22014 13.3196 6.33175C14.7977 6.96297 16.2717 7.60626 17.7422 8.25784C17.8511 8.30611 17.9648 8.43582 18.0061 8.55573C18.6869 10.552 19.3554 12.5527 20.032 14.5505C20.083 14.7013 20.0865 14.8235 19.9728 14.9268C19.7495 15.1289 19.5263 15.3302 19.2947 15.5195C19.1665 15.6251 19.0563 15.5768 18.9364 15.4562C17.3501 13.8581 15.7583 12.2661 14.1672 10.6719C13.793 10.2971 13.4147 9.92828 13.0481 9.54593C12.8751 9.36493 12.6877 9.31667 12.4589 9.36192C11.5638 9.5399 10.8368 10.0565 10.1842 10.7179C9.88239 11.0241 9.52337 11.19 9.12577 11.248C8.5614 11.3302 8.21203 10.929 8.31333 10.3144C8.34916 10.0949 8.39946 9.86493 8.49731 9.67338C9.25187 8.19223 10.2559 6.98786 11.7202 6.32647C11.9621 6.21712 12.2294 6.17338 12.5003 6.0957H12.5009Z"
        fill="#6B7280"
      />
      <path
        d="M22.9991 11.0258C22.9991 12.3976 22.9998 13.7702 22.9991 15.142C22.9991 15.6465 22.8619 15.795 22.4016 15.7981C22.23 15.7988 22.0585 15.7988 21.8862 15.7981C21.4734 15.7958 21.3583 15.7106 21.2171 15.292C20.6885 13.7257 20.1614 12.1585 19.6335 10.5914C19.3283 9.68494 19.0216 8.77845 18.717 7.87197C18.5406 7.34708 18.6357 7.11028 19.1209 6.92099C20.0656 6.55145 21.0524 6.41043 22.0433 6.29731C22.1625 6.28373 22.2831 6.27468 22.4023 6.26186C22.8027 6.21887 22.9977 6.4059 22.9984 6.85236C23.0011 8.24301 22.9991 9.63441 22.9991 11.0251V11.0258Z"
        fill="#6B7280"
      />
      <path
        d="M1 9.76347C1 8.39997 1 7.03572 1 5.67222C1.00069 5.12923 1.15711 4.96634 1.65877 5.00555C2.63384 5.08248 3.57445 5.32154 4.4751 5.74236C4.80311 5.8962 4.91956 6.13979 4.81758 6.51159C4.11815 9.05608 3.41389 11.5983 2.70964 14.1413C2.63315 14.4166 2.45743 14.5599 2.1942 14.5666C1.98816 14.5712 1.78143 14.5704 1.57539 14.5681C1.14953 14.5636 1.00138 14.4045 1.00069 13.9399C0.999311 12.5478 1.00069 11.1556 1.00069 9.76423L1 9.76347Z"
        fill="#6B7280"
      />
    </svg>
  );
};
