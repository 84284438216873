import { useCallback, useEffect } from 'react';

function useClickOutside<T extends HTMLElement>(ref: React.RefObject<T>, callback: () => void) {
  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    },
    [callback, ref]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [ref, callback, handleClick]);
}

export default useClickOutside;
