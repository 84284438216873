'use client';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from '@/i18n/client';
import { useParams, usePathname, useRouter } from 'next/navigation';
import { Locale } from '../../../i18n-config';
import LanguageChangeDropdown, { LanguageMapper, lngFlagMapper } from '../common/LanguageChangeDropdown';
import Image from 'next/image';
import Link from 'next/link';
import { debounce } from 'lodash';
import {
  BiArrowBack,
  BiCaretDown,
  BiCaretRight,
  BiCaretUp,
  BiCheck,
  BiDollarCircle,
  BiMenu,
  BiMoviePlay,
  BiSolidBot,
  BiSolidConversation,
  BiSolidHome,
  BiSolidZap,
} from 'react-icons/bi';
import useLinks from '@/hooks/useLinks';
import { useWindowSize } from '@/hooks/useWindowSize';
import useClickOutside from '@/hooks/useClickOutside';
import HumanIcon from '../common/icons/Human.icon';
import { BusinessPartnerPlanIcon } from '../common/icons/businessPartnerPlanIcon.icon';

type Props = {
  theme?: string;
};

type FeatureProps = {
  feature: {
    menu: Array<{
      title: string;
      description: string;
      icon: React.ReactNode;
      link: string;
    }>;
    blogs: Array<{
      title: string;
      description: string;
      imageUrl: string;
      link: string;
    }>;
    viewAllPost: {
      title: string;
      link: string;
    };
  };
};

export const NAVIGATION_CONSTANTS = {
  SCROLL_THRESHOLD: 10,
  MOBILE_BREAKPOINT: 888,
  Z_INDEX: 99999999,
};

export default function SiteNavbarComponent({ theme }: Props) {
  const params = useParams();
  const lang = params?.lang;
  const language = Array.isArray(lang) ? lang[0] : lang;

  const pathname = usePathname();
  const { t, i18n } = useTranslation((lang as Locale) || 'defaultLocale');
  const [model, setModel] = useState<'lg' | 'sm'>('lg');
  const { Links } = useLinks();
  const [width, height] = useWindowSize();

  const [feature, setFeature] = useState({
    menu: [
      {
        title: t('HeaderMenu/features/aiChatbots/title'),
        description: t('HeaderMenu/features/aiChatbots/description'),
        icon: <BiSolidBot className="tw-w-6 tw-h-6 tw-text-gray-500" />,
        link: Links.AIChatbot.href,
      },
      {
        title: t('HeaderMenu/features/chatbotsWithFlow/title'),
        description: t('HeaderMenu/features/chatbotsWithFlow/description'),
        icon: <BiSolidConversation className="tw-w-6 tw-h-6 tw-text-gray-500" />,
        link: Links.AIChatbot.href,
      },
      {
        title: t('HeaderMenu/features/videoPopUp/title'),
        description: t('HeaderMenu/features/videoPopUp/description'),
        icon: <BiMoviePlay className="tw-w-6 tw-h-6 tw-text-gray-500" />,
        link: Links.VideoBot.href,
      },
      {
        title: t('HeaderMenu/features/aiClone/title'),
        description: t('HeaderMenu/features/aiClone/description'),
        icon: <HumanIcon />,
        link: Links.HumanClone.href,
      },
    ],
    blogs: [
      {
        title: t('HeaderMenu/blog/posts/post1/title'),
        description: t('HeaderMenu/blog/posts/post1/description'),
        imageUrl:
          'https://cdn.1cdn.app/application/LOUISEBOT/24081711380352_Why_Choose_a_Video_Chatbot_Over_a_Text-Based_Chatbot.png',
        link: Links.AIChatbot.href,
      },
      {
        title: t('HeaderMenu/blog/posts/post2/title'),
        description: t('HeaderMenu/blog/posts/post2/description'),
        imageUrl: 'https://cdn.1cdn.app/application/LOUISEBOT/24080506275418_stripe.png',
        link: Links.AIChatbot.href,
      },
    ],
    viewAllPost: {
      title: t('HeaderMenu/blog/viewAll'),
      link: '',
    },
  });

  const shouldRender = !pathname?.includes('chat/');

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [i18n, language]);

  useEffect(() => {
    if (width <= NAVIGATION_CONSTANTS.MOBILE_BREAKPOINT) {
      setModel('sm');
    } else {
      setModel('lg');
    }
  }, [width]);

  if (!shouldRender) return null;

  return (
    <React.Fragment>
      {model == 'lg' && <DesktopMenu feature={feature} />}
      {model == 'sm' && <MobileMenu feature={feature} />}
    </React.Fragment>
  );
}

const MobileMenu = memo(({ feature }: FeatureProps) => {
  const params = useParams();
  const lang = params?.lang;
  const pathname = usePathname();
  const { t } = useTranslation((lang as Locale) || 'defaultLocale');
  const { Links } = useLinks();

  const [showMenu, setShowMenu] = useState(false);
  const [showFeature, setFeature] = useState(false);
  const [showLanguage, setShowLanguage] = useState(false);

  const router = useRouter();

  const onClose = () => {
    setShowLanguage(false);
    setShowMenu(false);
    setFeature(false);
  };

  const selectHandler = (locale: string) => {
    if (!pathname) return '/en-US';
    const segments = pathname.split('/');
    segments[1] = locale;
    return segments.join('/');
  };

  const handleClick = (url: string) => {
    onClose();
    router.push(url);
  };

  const displayLanguageName = () => {
    if (!pathname) return LanguageMapper['en-US'];
    const segments = pathname.split('/');
    return LanguageMapper[segments[1]];
  };

  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = debounce(() => {
      setScrolled(window.scrollY > NAVIGATION_CONSTANTS.SCROLL_THRESHOLD);
    }, 100);

    window.addEventListener('scroll', handleScroll);
    return () => {
      handleScroll.cancel();
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const refMenu = useRef(null);

  useClickOutside(refMenu, () => {
    setShowMenu(false);
  });

  const refFeature = useRef(null);

  useClickOutside(refFeature, () => {
    setFeature(false);
  });

  const refLanguage = useRef(null);

  useClickOutside(refLanguage, () => {
    setShowLanguage(false);
  });

  useEffect(() => {
    if (showMenu || showFeature || showLanguage) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showMenu, showFeature, showLanguage]);

  return (
    <React.Fragment>
      <div
        ref={refMenu}
        style={{ zIndex: NAVIGATION_CONSTANTS.Z_INDEX }}
        className={`${scrolled ? 'tw-sticky top-0' : 'tw-flex'} lg:tw-hidden  tw-flex-col tw-relative tw-bg-[#192433]`}
      >
        <div
          className={`tw-flex  tw-w-full tw-h-16 tw-px-4 tw-py-6 tw-bg-[#192433] tw-justify-between tw-items-center `}
        >
          <div className="tw-w-full tw-justify-start tw-items-center tw-gap-10 tw-flex">
            <Link
              href={Links.home.href}
              className="tw-justify-start tw-items-center tw-gap-1 tw-flex"
              onClick={() => {
                setShowMenu(false);
              }}
            >
              <Image
                src="https://cdn.1cdn.app/application/LOUISEBOT/23091707385795_Logo_colorful.svg"
                alt="louisebot_logo"
                title="louisebot_logo"
                width={40}
                height={40}
              />
              <div className="tw-text-[#4167DA] tw-text-lg tw-font-extrabold tw-font-['Lexend'] tw-leading-7">
                LouiseBot
              </div>
            </Link>
          </div>
          <div
            className="tw-w-full tw-flex tw-justify-end tw-items-center"
            onClick={() => {
              setShowMenu((prev) => !prev);
            }}
          >
            <BiMenu className="tw-w-6 tw-h-6 tw-relative tw-text-white" />
          </div>
        </div>
        {showMenu && (
          <div className="tw-h-[calc(100vh-60px)] tw-overflow-y-scroll tw-shadow-lg tw-absolute tw-top-16 tw-z-50 tw-left-0 tw-w-full  tw-px-2 tw-pt-2  tw-bg-gray-900 tw-flex-col tw-justify-start tw-items-start tw-gap-6 tw-inline-flex">
            <div className="tw-self-stretch  tw-flex-col tw-justify-start tw-items-start tw-gap-2 tw-flex">
              <div className="tw-self-stretch tw-px-4 tw-py-3 tw-rounded-lg tw-justify-start tw-items-center tw-gap-3 tw-inline-flex">
                <BiSolidHome className="tw-w-6 tw-h-6 tw-relative tw-text-gray-500" />
                <Link
                  onClick={() => {
                    setShowMenu(false);
                  }}
                  href={Links.home.href}
                  className="tw-text-white tw-text-base tw-font-normal tw-font-['Lexend'] tw-leading-normal"
                >
                  {Links.home.name}
                </Link>
              </div>
              <div
                onClick={() => {
                  setShowMenu(false);
                  setFeature(true);
                }}
                className="tw-self-stretch tw-px-4 tw-py-3 tw-rounded-lg tw-justify-between tw-items-center tw-inline-flex"
              >
                <div className="tw-cursor-pointer tw-justify-start tw-items-center tw-gap-3 tw-flex">
                  <BiSolidZap className="tw-w-6 tw-h-6 tw-relative tw-text-gray-500" />
                  <div className="tw-text-white tw-text-base tw-font-normal tw-font-['Lexend'] tw-leading-normal">
                    {t('SiteNavbar/l-10')}
                  </div>
                </div>
                <div>
                  <BiCaretRight className="tw-w-4 tw-h-4  tw-text-white" />
                </div>
              </div>
              <Link
                onClick={() => {
                  setShowMenu(false);
                }}
                href={Links.pricing.href}
                className="tw-self-stretch tw-px-4 tw-py-3 tw-bg-gray-900 tw-rounded-lg tw-justify-start tw-items-center tw-gap-3 tw-inline-flex"
              >
                <BiDollarCircle className="tw-w-6 tw-h-6 tw-relative tw-text-gray-500" />
                <div className="tw-text-white tw-text-base tw-font-normal tw-font-['Lexend'] tw-leading-normal">
                  {Links.pricing.name}
                </div>
              </Link>
              <Link
                onClick={() => {
                  setShowMenu(false);
                }}
                href={Links.partnership.href}
                className="tw-self-stretch tw-px-4 tw-py-3 tw-bg-gray-900 tw-rounded-lg tw-justify-start tw-items-center tw-gap-3 tw-inline-flex"
              >
                <BusinessPartnerPlanIcon />
                <div className="tw-text-white tw-text-base tw-font-normal tw-font-['Lexend'] tw-leading-normal">
                  {t('SiteNavbar/l-15')}
                </div>
              </Link>

              <div
                onClick={() => {
                  setShowMenu(false);
                  setShowLanguage(true);
                }}
                className="tw-self-stretch tw-px-4 tw-py-3 tw-bg-gray-900 tw-rounded-lg tw-justify-start tw-items-center tw-gap-3 tw-inline-flex"
              >
                <div className="tw-flex tw-flex-row tw-items-center tw-w-full tw-gap-2">
                  <Image
                    src={lngFlagMapper[lang as string]}
                    alt={lang as string}
                    width={28}
                    height={28}
                    className="tw-rounded-full"
                    title={(lang as string) + ' flag'}
                  />
                  <div className="tw-text-white tw-text-base tw-font-normal tw-font-['Lexend'] tw-leading-normal">
                    {displayLanguageName()}
                  </div>
                </div>
                <div>
                  <BiCaretRight className="tw-w-4 tw-h-4  tw-text-white" />
                </div>
              </div>
            </div>
            <div className="tw-flex tw-flex-row-reverse tw-gap-3 tw-p-2 tw-w-full tw-items-center">
              <Link
                onClick={() => {
                  setShowMenu(false);
                }}
                href={Links.register.href}
                className=" tw-w-full tw-self-stretch tw-px-6 tw-py-3 tw-bg-blue-600 tw-rounded-full tw-justify-center tw-items-center tw-inline-flex"
              >
                <div className="tw-text-white tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight">
                  {Links.register.name}
                </div>
              </Link>
              <Link
                onClick={() => {
                  setShowMenu(false);
                }}
                href={Links.login.href}
                className="tw-w-full tw-self-stretch tw-px-6 tw-py-3 tw-bg-gray-700 tw-rounded-full tw-justify-center tw-items-center tw-inline-flex"
              >
                <div className="tw-text-white tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight">
                  {Links.login.name}
                </div>
              </Link>
            </div>
          </div>
        )}

        {showFeature && (
          <div
            ref={refFeature}
            className={` tw-h-[calc(100vh-60px)] tw-overflow-y-scroll  tw-w-full tw-shadow-lg tw-absolute tw-top-16 tw-z-50 tw-left-0 tw-flex tw-flex-col  tw-bg-gray-900 `}
          >
            <div className=" tw-px-4 tw-pt-4 tw-pb-8 tw-flex-col tw-justify-start tw-items-start tw-gap-10 tw-inline-flex">
              <div className="tw-self-stretch tw-flex-col tw-justify-center tw-items-center tw-gap-5 tw-flex">
                <div
                  onClick={() => {
                    setFeature(false);
                    setShowMenu(true);
                  }}
                  className="tw-w-full tw-px-3 tw-py-2.5 tw-rounded-lg tw-justify-start tw-items-center tw-gap-3 tw-inline-flex"
                >
                  <BiArrowBack className="tw-w-6 tw-h-6 tw-relative tw-text-white" />
                  <div className="tw-text-white tw-text-base tw-font-normal tw-font-['Lexend'] tw-leading-normal">
                    {t('HeaderMenu/BacktoMainMenu')}
                  </div>
                </div>
                <div className=" tw-flex-col tw-justify-start tw-items-start tw-flex">
                  {feature.menu.map((item, index) => (
                    <Link
                      key={index}
                      href={item.link}
                      className="tw-self-stretch tw-p-3 tw-rounded-lg tw-justify-start tw-items-start tw-gap-4 tw-inline-flex"
                    >
                      {item?.icon}
                      <div
                        onClick={onClose}
                        className="tw-grow tw-shrink tw-basis-0 tw-flex-col tw-justify-start tw-items-start tw-gap-1 tw-inline-flex"
                      >
                        <div className="tw-self-stretch tw-text-white tw-text-base tw-font-medium tw-font-['Inter'] tw-leading-normal">
                          {item?.title}
                        </div>
                        <div className="tw-self-stretch tw-text-gray-400 tw-text-sm tw-font-normal tw-font-['Inter'] tw-leading-tight">
                          {item?.description}
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
            <div className=" tw-px-4 tw-pt-8  tw-pb-32  tw-flex-col tw-justify-start tw-items-start tw-gap-6 tw-inline-flex">
              <div className="tw-self-stretch tw-justify-start tw-items-center tw-inline-flex">
                <div className="tw-grow tw-shrink tw-basis-0 tw-text-gray-400 tw-text-sm tw-font-medium tw-font-['Inter'] tw-uppercase tw-leading-tight tw-tracking-tight">
                  {t('SiteNavbar/l-16')}
                </div>
              </div>
              {feature.blogs.map((item, index) => (
                <Link
                  onClick={onClose}
                  key={index}
                  href={item.link}
                  className="tw-relative tw-w-full tw-flex tw-flex-col tw-gap-3"
                >
                  <div className="tw-p-3  tw-flex-col tw-justify-start tw-items-start tw-gap-1 tw-inline-flex">
                    <Image src={item.imageUrl} alt="louisebot_logo" title="louisebot_logo" width={772} height={424} />
                    <div className="tw-mt-2 tw-self-stretch tw-text-white tw-text-base tw-font-medium tw-font-['Inter'] tw-leading-normal">
                      {item.title}
                    </div>
                    <div className="tw-self-stretch tw-text-gray-400 tw-text-sm tw-font-normal tw-font-['Inter'] tw-leading-tight">
                      {item.description}
                    </div>
                  </div>
                </Link>
              ))}

              {/* <Link
                href={feature.viewAllPost.link}
                className="tw-self-stretch tw-justify-start tw-items-start tw-inline-flex tw-w-full"
              >
                <div className="tw-text-blue-600 tw-text-sm tw-font-medium tw-font-['Inter'] tw-leading-tight">
                  {feature.viewAllPost.title} →
                </div>
              </Link> */}
            </div>
          </div>
        )}

        {showLanguage && (
          <div
            ref={refLanguage}
            className="tw-h-[calc(100vh-60px)] tw-w-full tw-shadow-lg  tw-px-4 tw-pt-4 tw-pb-8 tw-bg-gray-900  tw-flex-col tw-justify-start tw-items-start tw-gap-10 tw-inline-flex"
          >
            <div className="tw-self-stretch tw-h-40 tw-flex-col tw-justify-center tw-items-center tw-gap-5 tw-flex">
              <div
                onClick={() => {
                  setShowLanguage(false);
                  setShowMenu(true);
                }}
                className="tw tw-w-full tw-px-3 tw-py-2.5 tw-rounded-lg tw-justify-start tw-items-center tw-gap-3 tw-inline-flex"
              >
                <BiArrowBack className="tw-w-6 tw-h-6 tw-relative tw-text-white" />
                <div className="tw-text-white tw-text-base tw-font-normal tw-font-['Lexend'] tw-leading-normal">
                  {t('HeaderMenu/BacktoMainMenu')}
                </div>
              </div>
              <div className="tw-w-full tw-h-24 tw-flex-col tw-justify-start tw-items-start tw-flex">
                <div className="tw-self-stretch tw-h-24 tw-flex-col tw-justify-start tw-items-start tw-gap-4 tw-flex">
                  <div
                    onClick={() => {
                      handleClick(selectHandler('en-US'));
                    }}
                    className="tw-self-stretch tw-px-3 tw-py-2.5 tw-rounded-lg tw-justify-between tw-items-center tw-inline-flex"
                  >
                    <div className="tw-justify-start tw-items-center tw-gap-3 tw-flex">
                      <Image
                        src={lngFlagMapper['en-US']}
                        alt={'en-US'}
                        width={28}
                        height={28}
                        className="tw-rounded-full"
                        title={'en-US' + ' flag'}
                      />
                      <div className="tw-text-white tw-text-base tw-font-normal tw-font-['Lexend'] tw-leading-normal">
                        English
                      </div>
                    </div>
                    {lang == 'en-US' && <BiCheck className="tw-w-6 tw-h-6 tw-relative tw-text-blue-500" />}
                  </div>
                  <div
                    onClick={() => {
                      handleClick(selectHandler('fr-FR'));
                    }}
                    className="tw-w-full tw-self-stretch tw-pl-3 tw-pr-4 tw-py-2.5 tw-rounded-lg tw-justify-start tw-items-center tw-gap-3 tw-inline-flex"
                  >
                    <div className="tw-w-full tw-justify-start tw-items-center tw-gap-3 tw-flex">
                      <Image
                        src={lngFlagMapper['fr-FR']}
                        alt={'fr-FR'}
                        width={28}
                        height={28}
                        className="tw-rounded-full"
                        title={'fr-FR' + ' flag'}
                      />
                      <div className="tw-text-white tw-text-base tw-font-normal tw-font-['Lexend'] tw-leading-normal">
                        France
                      </div>
                    </div>
                    {lang != 'en-US' && <BiCheck className="tw-w-6 tw-h-6 tw-relative tw-text-blue-500" />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
});

const DesktopMenu = memo(({ feature }: FeatureProps) => {
  const [scrolled, setScrolled] = useState(false);
  const params = useParams();
  const lang = params?.lang;
  const { t } = useTranslation((lang as Locale) || 'defaultLocale');

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 1); // Change threshold as needed
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const { Links } = useLinks();

  const [showFeatures, setShowFeatures] = useState(false);

  const ref = useRef(null);

  useClickOutside(ref, () => {
    setShowFeatures(false);
  });

  return (
    <div
      ref={ref}
      style={{ zIndex: 99999999 }}
      className={`${
        scrolled ? 'tw-sticky top-0' : 'lg:tw-flex tw-hidden'
      }  tw-bg-[#192433] tw-w-full  tw-flex-col tw-justify-center tw-items-center tw-relative`}
    >
      <div className="tw-w-full tw-p-6 tw-bg-[#192433] tw-justify-between tw-items-center tw-inline-flex">
        <div className="tw-justify-start tw-items-center tw-gap-8 tw-flex">
          <div className="tw-justify-start tw-items-center tw-gap-10 tw-flex">
            <Link href={Links.home.href} className="tw-justify-start tw-items-center tw-gap-1 tw-flex">
              <Image
                src="https://cdn.1cdn.app/application/LOUISEBOT/23091707385795_Logo_colorful.svg"
                alt="logo"
                title="logo"
                width={40}
                height={40}
              />
              <div className="tw-text-[#4167DA] tw-text-lg tw-font-extrabold tw-font-['Lexend'] tw-leading-7">
                LouiseBot
              </div>
            </Link>
          </div>
          <div className="tw-justify-start tw-items-center tw-gap-8 tw-flex">
            <Link
              href={Links.home.href}
              className="tw-text-white tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight"
            >
              {Links.home.name}
            </Link>
            <div
              onClick={() => {
                setShowFeatures((prev) => !prev);
              }}
              className="tw-cursor-pointer tw-justify-center tw-items-center tw-gap-1 tw-flex tw-relative"
            >
              <div className="tw-text-white tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight">
                {t('SiteNavbar/l-10')}
              </div>
              {!showFeatures && <BiCaretDown className="tw-text-white tw-w-4 tw-h-4 tw-relative" />}
              {showFeatures && <BiCaretUp className="tw-text-white tw-w-4 tw-h-4 tw-relative" />}
            </div>
            <Link
              onClick={() => {
                setShowFeatures(false);
              }}
              href={Links.pricing.href}
              className="tw-text-white tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight"
            >
              {Links.pricing.name}
            </Link>
            <Link
              onClick={() => {
                setShowFeatures(false);
              }}
              href={Links.partnership.href}
              className="tw-justify-center tw-items-center tw-gap-1 tw-flex"
            >
              <div className="tw-text-white tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight">
                {t('SiteNavbar/l-15')}
              </div>
              <div className="tw-w-4 tw-h-4 tw-relative" />
            </Link>
          </div>
        </div>
        <div className="tw-h-9 tw-justify-end tw-items-center tw-gap-3 tw-flex">
          <div className="tw-pl-1 tw-pr-1.5 tw-py-1 tw-bg-[#192433] tw-rounded-full tw-justify-start tw-items-center tw-gap-0.5 tw-flex">
            <LanguageChangeDropdown id="language-dropdown-navbar" />
          </div>
          <Link
            onClick={() => {
              setShowFeatures(false);
            }}
            href={Links.login.href}
            className="tw-px-4 tw-py-2 tw-bg-gray-700 tw-rounded-full tw-justify-center tw-items-center tw-flex"
          >
            <div className="tw-text-white tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight">
              {Links.login.name}
            </div>
          </Link>
          <Link
            onClick={() => {
              setShowFeatures(false);
            }}
            href={Links.register.href}
            className="tw-px-4 tw-py-2 tw-bg-blue-600 tw-rounded-full tw-justify-center tw-items-center tw-flex"
          >
            <div className="tw-text-white tw-text-sm tw-font-normal tw-font-['Lexend'] tw-leading-tight">
              {Links.register.name}
            </div>
          </Link>
        </div>
      </div>
      {showFeatures && (
        <div className="tw-p-6 tw-w-full tw-absolute tw-top-20 tw-bg-gray-900 tw-z-50">
          <div className="tw-container tw-grid tw-grid-cols-2 tw-m-auto">
            <div className="tw-pl-8 tw-pr-12 tw-py-9 tw-justify-start tw-items-start tw-gap-8 tw-inline-flex">
              <div className="tw-grow tw-shrink tw-basis-0 tw-flex-col tw-justify-start tw-items-start tw-inline-flex tw-gap-3">
                {feature.menu.map((item, index) => (
                  <Link
                    onClick={() => {
                      setShowFeatures(false);
                    }}
                    key={index}
                    href={item.link}
                    className="tw-self-stretch tw-h-16 tw-p-3 tw-rounded-lg tw-justify-start tw-items-start tw-gap-4 tw-inline-flex"
                  >
                    {item.icon}
                    <div className="tw-grow tw-shrink tw-basis-0 tw-flex-col tw-justify-start tw-items-start tw-gap-1 tw-inline-flex">
                      <div className="tw-self-stretch tw-text-white tw-text-base tw-font-medium tw-font-['Inter'] tw-leading-normal">
                        {item.title}
                      </div>
                      <div className="tw-self-stretch tw-text-gray-400 tw-text-sm tw-font-normal tw-font-['Inter'] tw-leading-tight">
                        {item.description}
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
            <div className="tw-pl-12 tw-pr-8 tw-py-12 tw-flex-col tw-justify-start tw-items-start tw-gap-6 tw-inline-flex">
              <div className="tw-self-stretch tw-justify-start tw-items-center tw-inline-flex">
                <div className="tw-grow tw-shrink tw-basis-0 tw-text-gray-400 tw-text-sm tw-font-medium tw-font-['Inter'] tw-uppercase tw-leading-tight tw-tracking-tight">
                  From the blog
                </div>
              </div>
              <div className=" tw-relative tw-w-full tw-flex tw-flex-col">
                {feature.blogs.map((item, index) => (
                  <Link
                    onClick={() => {
                      setShowFeatures(false);
                    }}
                    href={item.link}
                    key={index}
                    className="tw-w-full tw-p-3 tw-rounded-lg tw-justify-start tw-items-start tw-gap-8 tw-inline-flex"
                  >
                    <img
                      src={item.imageUrl}
                      className="tw-w-32 tw-h-20 tw-relative tw-rounded-md tw-object-scale-down"
                      width={772}
                      height={424}
                    />
                    <div className="tw-grow tw-shrink tw-basis-0 tw-flex-col tw-justify-start tw-items-start tw-gap-1 tw-inline-flex">
                      <div className="tw-self-stretch tw-text-white tw-text-base tw-font-medium tw-font-['Inter'] tw-leading-normal">
                        {item.title}
                      </div>
                      <div className="tw-self-stretch tw-text-gray-400 tw-text-sm tw-font-normal tw-font-['Inter'] tw-leading-tight">
                        {item.description}
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
              {/* <Link
                href={feature.viewAllPost.link}
                className=" tw-w-full tw-self-stretch tw-justify-start tw-items-start tw-inline-flex"
              >
                <div className="tw-text-blue-500 tw-text-sm tw-font-medium tw-font-['Inter'] tw-leading-tight">
                  {feature.viewAllPost.title} →
                </div>
              </Link> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

MobileMenu.displayName = 'MobileMenu';
DesktopMenu.displayName = 'DesktopMenu';
