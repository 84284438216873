import React from 'react';
import { StorageContextType } from './types';

const StorageContext = React.createContext<StorageContextType>(null!);
const CookieManagementContext = React.createContext(false);
const chatThemeContext = React.createContext<'light' | 'dark'>('light');

const Contexts = {
  StorageContext,
  CookieManagementContext,
  chatThemeContext,
};

export default Contexts;
